import React from "react";

import { Redirect } from "react-router";

import Joi from "joi-browser";

import Form from "./common/form/form";
import auth from "../services/authService";
import KeyIcon from "../graphics/key.svg";
import { toast } from "react-toastify";

class ResetPassword extends Form {
  state = {
    data: {
      email: "",
      password: "",
      password_confirmation: "",
      token: this.props.match.params.token,
    },

    errors: {},
  };

  schema = {
    email: Joi.string().required().email().label("Nutzername"),
    password: Joi.string().required().min(8).label("Passwort"),
    password_confirmation: Joi.any()
      .valid(Joi.ref("password"))
      .required()
      .options({
        language: {
          any: { allowOnly: "muss mit dem Passwort übereinstimmen" },
        },
      }),
    token: Joi.string().required(),
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const email = query.get("email");
    const data = { ...this.state.data };
    data.email = email;
    this.setState({ data });
  }

  doSubmit = async () => {
    const { data } = this.state;
    auth
      .resetPassword(
        data.email,
        data.password,
        data.password_confirmation,
        data.token
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Passwort erfolgreich geändert!");
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(
            "Ungültiger Token: Bitte fordere ein neues E-Mail zum zurücksetzen an!"
          );
        } else {
          toast.error("Fehler beim Zurücksetzen!");
        }
      });
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <React.Fragment>
        <div className="signin-container">
          <form onSubmit={this.handleSubmit} className="jumbotron form-signin">
            <img
              className="mb-4"
              src={KeyIcon}
              alt=""
              width="72"
              height="72"
              id="login-img"
            />
            <h1 className="h3 mb-3 font-weight-normal">
              Neuses Passwort festlegen
            </h1>

            {this.renderInputField("email", "Nutzername (E-Mail)")}
            {this.renderInputField("password", "Neues Passwort", "password")}
            {this.renderInputField(
              "password_confirmation",
              "Neues Passwort wiederholen",
              "password"
            )}

            {this.renderSubmitButton("Senden")}
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default ResetPassword;
