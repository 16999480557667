import React, { Component } from "react";
import { Route, Switch } from "react-router";
import EditQuote from "./editQuote";
import Quotes from "./quotes";
import ShowQuote from "./showQuote";

/**
 * Master component to host all quotes components (table, view, edit)
 */
class QuotesModule extends Component {
  state = {
    currentQuotes: this.props.quotes,
  };

  handleListChange = (quotes) => {
    this.setState({ currentQuotes: quotes }); // All currently listed quotes (filtered and sortet, but without pagination)
  };

  /**
   * Get the quote which is in the currentQuotes list after the given one
   * @param {*} quote
   * @returns
   */
  getNextQuote = (quote) => {
    const index = this.state.currentQuotes.indexOf(quote);
    const nextQuote = this.state.currentQuotes[index + 1];
    return nextQuote ? nextQuote.id : -1;
  };

  /**
   *Get the quote which is in the currentQuotes list before the given one
   * @param {*} quote
   * @returns
   */
  getPreviousQuote = (quote) => {
    const index = this.state.currentQuotes.indexOf(quote);
    const previosQuote = this.state.currentQuotes[index - 1];
    return previosQuote ? previosQuote.id : -1;
  };

  getNextQuoteById = (id) => {
    let result = -1;
    this.state.currentQuotes.forEach((quote) => {
      if (quote.id === id) {
        result = this.getNextQuote(quote);
      }
    });
    return result;
  };

  getPreviousQuoteById = (id) => {
    let result = -1;
    this.state.currentQuotes.forEach((quote) => {
      if (quote.id === id) {
        result = this.getPreviousQuote(quote);
      }
    });
    return result;
  };

  render() {
    return (
      <div className="quotes-container">
        <Switch>
          <Route
            path="/quotes/:id/edit"
            render={(props) => (
              <EditQuote
                {...props}
                user={this.props.user}
                quotes={this.props.quotes}
                bible_books={this.props.bible_books}
                onRefresh={this.props.onRefresh}
              />
            )}
          />
          <Route
            path="/quotes/:id"
            render={(props) => (
              <ShowQuote
                {...props}
                user={this.props.user}
                quotes={this.props.quotes}
                bible_books={this.props.bible_books}
                onRefresh={this.props.onRefresh}
                getNextQuote={this.getNextQuoteById}
                getPreviousQuote={this.getPreviousQuoteById}
              />
            )}
          />
          <Route
            path="/quotes"
            quotes={this.props.quotes}
            render={(props) => (
              <Quotes
                {...props}
                user={this.props.user}
                quotes={this.props.quotes}
                bible_books={this.props.bible_books}
                onRefresh={this.props.onRefresh}
                onListChange={this.handleListChange}
                getNextQuote={this.getNextQuote}
                getPreviousQuote={this.getPreviousQuote}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default QuotesModule;
