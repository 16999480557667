import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

/**
 * Route that can only be accessed by an admin
 * @param {*} param0
 * @returns
 */
const AdminRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        // If user is not logged in redirect to login
        if (!auth.getCurrentUser())
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        // If user is logged in but not an admin redirect to home
        else if (!auth.getCurrentUser().admin)
          return <Redirect to={{ pathname: "/" }} />;

        // If user is logged in and is an admin, let them go to the admin content ;-)
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default AdminRoute;
