import http from "./httpService";

const apiEndpoint = "/user";

export function getUser() {
  return http.get(apiEndpoint);
}

export function addUser(name, email, password) {
  return http.post("/auth/register", {
    name: name,
    email: email,
    password: password,
    password_confirmation: password,
  });
}

export function deleteUser(id) {
  return http.delete(apiEndpoint + "/" + id);
}

const userService = {
  getUser,
  addUser,
  deleteUser,
};

export default userService;
