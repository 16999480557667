import http from "./httpService";

const apiEndpoint = "/bible-books";

export function getBibleBooks() {
  return http.get(apiEndpoint);
}

export function saveBibleBook(book) {
  if (book.id > -1) {
    const body = { ...book };
    delete body.id;
    return http.put(apiEndpoint + "/" + book.id, body);
  }

  return http.post(apiEndpoint, book);
}

export function deleteBibleBook(id) {
  return http.delete(apiEndpoint + "/" + id);
}

export const testaments = [
  { id: 0, name: "AT" },
  { id: 1, name: "NT" },
];

const bibleBookService = {
  getBibleBooks,
  saveBibleBook,
  deleteBibleBook,
  testaments,
};

export default bibleBookService;
