import React from "react";
import { Link, NavLink } from "react-router-dom";

import { Navbar, Nav } from "react-bootstrap";

const NavBar = ({ user }) => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="/">Bibeldatenbank</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <NavLink className="nav-link" to="/quotes">
            Zitate
          </NavLink>
          {user && user.admin && (
            <React.Fragment>
              <NavLink className="nav-link" to="/bible-books">
                Bibel-Bücher
              </NavLink>
              <NavLink className="nav-link" to="/user">
                Benutzer
              </NavLink>
            </React.Fragment>
          )}
        </Nav>
        <Nav>
          {!user && (
            <Link to="/login" className="btn btn-primary ml-auto">
              Anmelden
            </Link>
          )}
          {user && (
            <React.Fragment>
              <div className="ml-auto">
                <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                  <span className="navbar-text" id="navbar-user">
                    {user.name + " (" + user.email + ")"}
                  </span>
                  <Link to="/logout" className="btn btn-danger">
                    Abmelden
                  </Link>
                </ul>
              </div>
            </React.Fragment>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
