import React from "react";
import Form from "../common/form/form";
import quotesService, { saveQuote } from "../../services/quotesService";
import bibleBookService from "../../services/bibleBooksService";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import auth from "../../services/authService";

class EditQuote extends Form {
  state = {
    data: {
      id: -1,
      quote_book: "",
      quote_bible: "",
      book_title: "",
      book_text_deviation: "",
      book_remarks: "",
      bible_book_verse: "",
      book_publisher_edition: "",
      book_edition_number: "",
      book_edition_year: "",
      book_page: "",
      bible_testament: "",
      bible_book: "",
      published: false,
      year_is_ca: false
    },
    errors: {},
    toast_id: null,
  };

  keys = quotesService.keyNames;

  schema = {
    id: Joi.number().min(-1).label(this.keys["id"]),
    quote_book: Joi.string().required().label(this.keys["quote_book"]),
    quote_bible: Joi.string().label(this.keys["quote_bible"]),
    book_title: Joi.string().label(this.keys["book_title"]),
    book_text_deviation: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label(this.keys["book_text_deviation"]),
    book_remarks: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label(this.keys["book_remarks"]),
    bible_book_verse: Joi.string().label(this.keys["bible_book_verse"]),
    book_publisher_edition: Joi.number()
      .min(0)
      .max(5)
      .label(this.keys["book_publisher_edition"]),
    book_edition_number: Joi.string()
      .optional()
      .allow("")
      .allow(null)
      .label(this.keys["book_edition_number"]),
    book_edition_year: Joi.number()
      .min(0)
      .max(2050)
      .label(this.keys["book_edition_year"]),
    book_page: Joi.string().label(this.keys["book_page"]),
    bible_testament: Joi.number()
      .min(0)
      .max(1)
      .label(this.keys["bible_testament"]),
    bible_book: Joi.number().min(0).label(this.keys["bible_book"]),
    published: Joi.boolean().label(this.keys["published"]),
    created_at: Joi.number(),
    updated_at: Joi.number(),
    creator: Joi.string().optional().allow(""),
    year_is_ca: Joi.boolean().label(this.keys["year_is_ca"])
  };

  async componentDidMount() {
    auth.checkLogin();
    this.props.onRefresh(); // Refresh the data in the App Component to make sure we have the newest data here
    if (this.props.match.params.id !== "new") this.populateQuote();
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.quotes !== this.props.quotes &&
      this.props.match.params.id !== "new"
    )
      this.populateQuote();
  }

  /**
   * Get the wanted quote out of the passed quotes-list
   */
  async populateQuote() {
    const idString = this.props.match.params.id;
    const id = parseInt(idString);

    let quoteFound = false;

    this.props.quotes.forEach((quote) => {
      if (quote.id === id) {
        quoteFound = true;
        toast.dismiss(this.state.toast_id); // Close the loading-toast
        this.setState({ data: this.mapToViewModel(quote), disabled: false }); // Set the data and enable the form
      }
    });

    // If the quote could not be found
    if (this.props.quotes.length > 0 && !quoteFound && !this.state.error_sent) {
      toast.dismiss(this.state.toast_id); // Close the loading-toast
      toast.error("Zitat konnte nicht geladen werden!");
      this.setState({ error_sent: true }); // set this property so we don't send the error twice
      this.props.history.push("/"); // Go to home page
    } else if (this.props.quotes.length === 0)
      // If we received an empty list, maybe the app component did not load the quotes from the server yet
      this.setState({ toast_id: toast("Lade Zitat..."), disabled: true });
  }

  mapToViewModel(quote) {
    return {
      id: quote.id,
      created_at: quote.created_at,
      updated_at: quote.updated_at,
      quote_book: quote.quote_book,
      quote_bible: quote.quote_bible,
      book_title: quote.book_title,
      book_text_deviation: quote.book_text_deviation,
      book_remarks: quote.book_remarks,
      bible_book_verse: quote.bible_book_verse,
      creator: quote.creator,
      book_publisher_edition: quote.book_publisher_edition,
      book_edition_number: quote.book_edition_number,
      book_edition_year: quote.book_edition_year,
      book_page: quote.book_page,
      bible_testament: quote.bible_testament,
      bible_book: quote.bible_book,
      published: quote.published,
      year_is_ca: quote.year_is_ca
    };
  }

  doSubmit = async () => {
    try {
      await saveQuote(this.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500)
        toast.error("Error " + ex.response.status);
      return;
    }

    this.props.match.params.id === "new"
      ? toast.success("Neues Zitat gespeichert")
      : toast.success("Zitat aktualisiert");
    this.props.history.push("/");
  };

  renderInputField(label, type = "text", min, max) {
    return super.renderInputField(label, this.keys[label], type, min, max);
  }

  renderInputSelect(label, options) {
    return super.renderInputSelect(label, this.keys[label], options);
  }

  renderInputCheckbox(label) {
    return super.renderInputCheckbox(label, this.keys[label]);
  }

  renderInputTextArea(label, rows) {
    return super.renderInputTextArea(label, this.keys[label], rows);
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="row jumbotron p-3 mt-3">
            <div className="col-12 d-flex flex-row mt-3 mb-3">
              {this.renderInputCheckbox("published")}

              <div className="ml-auto">
                {this.renderSubmitButton("Speichern")}
              </div>
            </div>
          </div>
          <div className="row jumbotron p-3 mt-3">
            {/* Quote */}
            <div className="col-6">
              {this.renderInputTextArea("quote_book", 5)}
            </div>
            <div className="col-6">
              {this.renderInputTextArea("quote_bible", 5)}
            </div>
            {/* Karyl May Book */}
            <div className="col-12">
              {this.renderInputTextArea("book_text_deviation", 2)}
            </div>{" "}
            <div className="col-12">
              {this.renderInputTextArea("book_remarks", 2)}
            </div>
          </div>

          <h4>Angaben zum Karl May Buch</h4>
          <div className="row jumbotron p-3 mt-3">
            <div className="col-12">{this.renderInputField("book_title")}</div>
            <div className="col">
              {this.renderInputSelect(
                "book_publisher_edition",
                quotesService.bookPublisherEditions
              )}
            </div>
            <div className="col">
              {this.renderInputField("book_edition_number")}
            </div>
            <div className="col">
              {this.renderInputField("book_edition_year", "number", 0, 2050)}
              {this.renderInputCheckbox("year_is_ca")}
            </div>
            <div className="col">{this.renderInputField("book_page")}</div>
          </div>

          <h4>Angaben zur Bibelstelle</h4>
          <div className="row jumbotron p-3 mt-3">
            <div className="col">
              {this.renderInputSelect(
                "bible_testament",
                bibleBookService.testaments
              )}
            </div>
            <div className="col">
              {this.renderInputSelect(
                "bible_book",
                this.props.bible_books.filter(
                  (book) =>
                    book.testament === parseInt(this.state.data.bible_testament)
                )
              )}
            </div>
            <div className="col">
              {this.renderInputField("bible_book_verse")}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default EditQuote;
