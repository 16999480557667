import React from "react";

const Input = ({ name, error, label, disabled, ...rest }) => {
  // ...rest -> rest operator to get all other properties from the props object
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        {...rest}
        name={name}
        id={name}
        className="form-control"
        disabled={disabled}
      />
      {error && <div className="alert alert-danger mt-1">{error}</div>}
    </div>
  );
};

export default Input;
