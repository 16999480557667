import React, { Component } from "react";
import { Link } from "react-router-dom";
import auth from "../../services/authService";
import Table from "../common/table/table";
import {keyNames} from "../../services/quotesService";

class QuotesTable extends Component {
  maxLength = 100;
  spacingStart = 10;
  spacingEnd = 10;

  columns = [
    {
      path:"id",
      label: "Datensatz-ID", 
      content: (quote) => (quote.id)
    },
    {
      path: "quote_book",
      label: "Zitat",
      content: (quote) => (
        <Link
          to={`/quotes/${quote.id}?p=${this.props.getPreviousQuote(
            quote
          )}&n=${this.props.getNextQuote(quote)}`}
        >
          {this.getShowableStringForQuote(quote, this.props.searchQuery)}
        </Link>
      ),
    },
    {
      path: "linked_quotes",
      label: "Verknüpfte Zitate",
      content: (quote) => quote.linked_quotes.length,
    },
  ];

  publishedColumn = {
    path: "published",
    label: "Status",
    content: (quote) => (quote.published ? "Veröffentlicht" : "Entwurf"),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user) this.columns.push(this.publishedColumn);
  }

  getKeyName = (key) => {
    if (keyNames[key] != null) return keyNames[key];
    return key;
  };

  /**
   * Return the showable string for a quote. Normally, this are just the first {maxLength} characters of quote.quote_book
   * If a searchQuery is present, this shows all properties of the quote, that contain the searchQuery
   * @param {*} quote 
   * @param {*} searchQuery 
   * @returns 
   */
  getShowableStringForQuote = (quote, searchQuery) => {
    if(searchQuery){
      const returnValues = [];
      for (const [key, value] of Object.entries(quote)) {
        if (
          value && key && value.toString().toLowerCase().includes(searchQuery.toLowerCase()))
        returnValues.push(
          <li key={quote.id + "-" + key}>
            {this.getShowableString(value, searchQuery, this.getKeyName(key))}
          </li>
        );
        else if(key === 'bible_book'){
          const bookName = this.props.bible_books.find((e) => e.id === value).name;
          if(bookName.toString().toLowerCase().includes(searchQuery.toLowerCase()))
            returnValues.push(
              <li key={quote.id + "-" + key}>
                {this.getShowableString(bookName, searchQuery, this.getKeyName(key))}
              </li>
            );
        }
      }
      if(returnValues.length !== 0)
        return <ul>{returnValues}</ul>;
    }
    return this.getShowableString(quote.quote_book, searchQuery);
  }

  /**
   * Returns a string where the search term is hihlighted and which is not longer than the defined maxLength (except when searchQuery + spacingStart + spacingEnd are longer than maxLength -> meaning long search queries)
   * @param {*} text The text to be (partially) displayed
   * @param {*} searchQuery The searched term to be higlighted
   * @param {*} label If in text search mode, the label of the text
   * @returns
   */
  getShowableString = (text, searchQuery, label) => {
    if(typeof text !== 'string') // Previously had crashes because text sometimes was a boolean value (?!?)
      return;
    const lowerText = text.toLowerCase();
    const lowerQuery = searchQuery.toLowerCase();

    let index = 0; // The index of the search term in the text

    if (searchQuery && lowerText.includes(lowerQuery)) {
      index = lowerText.indexOf(lowerQuery);

      let start; // The index of the start of the return string (in text)
      let end; // The index of the end of the return string (in text)

      if (text.length > this.maxLength) {
        start = index > this.spacingStart ? index - this.spacingStart : 0;
        start =
          start > 0 && start + this.maxLength > text.length
            ? text.length - this.maxLength
            : start;
        end =
          lowerQuery.length + this.spacingStart + this.spacingEnd <
          this.maxLength
            ? start + this.maxLength
            : start + lowerQuery.length + this.spacingStart + this.spacingEnd;
      } else {
        start = 0;
        end = text.length;
      }

      const first = text.substring(start, index);
      const second = text.substring(index, index + lowerQuery.length);
      const third = text.substring(index + lowerQuery.length, end);
      return (
        <>
          <b>{label}: </b>
          {start === 0 ? first : "..." + first}
          <span style={{ background: "#ebe534" }}>{second}</span>
          {end >= text.length ? third : third + "..."}
        </>
      );
    }

    if (text.length > this.maxLength) {
      return text.substring(index, index + this.maxLength) + "...";
    } else return text;
  };

  render() {
    const { quotes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={quotes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default QuotesTable;
