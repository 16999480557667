import "./App.css";
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Redirect, Route, Switch } from "react-router";
import NavBar from "./components/navBar";
import LoginForm from "./components/loginForm";
import auth from "./services/authService";
import Logout from "./components/logout";
import QuotesModule from "./components/quotes/quotesModule";
import BibleBooks from "./components/bible-books/bibleBooks";
import { getQuotes } from "./services/quotesService";
import { getBibleBooks } from "./services/bibleBooksService";

import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./components/forgot-password";
import ResetPassword from "./components/reset-password";
import UserTable from "./components/user/userTable";
import AdminRoute from "./components/common/adminRoute";
import Footer from "./components/footer";

class App extends Component {
  state = { quotes: [], bible_books: [] };

  componentDidMount() {
    this.populateUser();
    this.populateQuotes();
    auth.checkLogin();
  }

  populateUser() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  async populateQuotes() {
    const { data: quotes } = await getQuotes();
    const { data: bible_books } = await getBibleBooks();
    this.setState({
      quotes,
      bible_books,
    });
  }

  getBook(books, id) {
    books.find((element) => element.id === id);
  }

  handleRefresh = () => {
    this.populateUser();
    this.populateQuotes();
  };

  render() {
    const { user, quotes, bible_books } = this.state;

    return (
      <React.Fragment>
        <ToastContainer />
        <NavBar user={user} />

        <main className="container">
          <Switch>
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />

            <Route
              path="/quotes"
              render={(props) => (
                <QuotesModule
                  {...props}
                  user={user}
                  quotes={quotes}
                  bible_books={bible_books}
                  onRefresh={this.handleRefresh}
                />
              )}
            />
            <AdminRoute
              path="/bible-books"
              render={(props) => (
                <BibleBooks
                  {...props}
                  user={user}
                  books={bible_books}
                  onRefresh={this.handleRefresh}
                />
              )}
            />
            <AdminRoute
              path="/user"
              render={(props) => (
                <UserTable
                  {...props}
                  user={user}
                  books={bible_books}
                  onRefresh={this.handleRefresh}
                />
              )}
            />

            <Redirect from="/" exact to="/quotes" />
          </Switch>
        </main>
        <Footer user={user} />
      </React.Fragment>
    );
  }
}

export default App;
