import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";

import Pagination from "../common/pagination";
import QuotesTable from "./quotesTable";
import { paginate } from "../../utils/paginate";
import SearchField from "../common/searchField";
import DownloadQuotesList from "./downloadQuotes";
import { bookPublisherEditions } from "../../services/quotesService";
import DropdownFilter from "../common/dropdownFilter";

class Quotes extends Component {
  state = {
    currentPage: 1,
    searchQuery: "",
    bookPublisherDropdown: -1,
    bibleBookDropdown: -1,
    testamentDropdown: -1,
    statusDropdown: -1,
    pageSize: 10,
    currentQuotes: this.props.quotes,
    sortedQuotes: this.props.quotes, // Needed for CSV Download
    totalCount: 0,
    sortColumn: { path: "quote_book", order: "asc" },
    toast_id: null,
    is_loading_toast: false,
  };

  async componentDidMount() {
    this.props.onRefresh();
    this.setState(this.getPageData());

    if (this.props.quotes.length === 0)
      // No quotes received, probably the app component did not load them from the server yet
      this.setState({
        toast_id: toast("Lade Zitate..."),
        is_loading_toast: true,
      });
  }

  componentDidUpdate(prevProps, prevState) {
    // Dismiss loading toast if we already loaded the quotes
    if (this.props.quotes.length > 0 && this.state.is_loading_toast) {
      toast.dismiss(this.state.toast_id);
      this.setState({ is_loading_toast: false });
    }

    // If data, page, search or sort changed, update the displayed list
    if (
      prevProps.quotes !== this.props.quotes ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.searchQuery !== this.state.searchQuery ||
      prevState.bookPublisherDropdown !== this.state.bookPublisherDropdown ||
      prevState.bibleBookDropdown !== this.state.bibleBookDropdown ||
      prevState.testamentDropdown !== this.state.testamentDropdown ||
      prevState.statusDropdown !== this.state.statusDropdown ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.sortColumn !== this.state.sortColumn
    )
      this.setState(this.getPageData());
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleBookPublisherDropdown = (selection) => {
    this.setState({ bookPublisherDropdown: selection });
  };

  handleBibleBookDropdown = (selection) => {
    this.setState({ bibleBookDropdown: selection });
  };

  handleTestamentDropdown = (selection) => {
    this.setState({ testamentDropdown: selection });
  };

  handleStatusDropdown = (selection) => {
    this.setState({ statusDropdown: selection });
  };

  // Check if the given quote should be displayed with the current filters
  filterQuotes = (quote) => {
    const { searchQuery } = this.state;
    const searchL = searchQuery.toLowerCase();

    if (this.filterForDropdowns(quote) === false) return false;

    // Loop trough all the fields of the quote and return true, if one of them qualifies
    for (const [key, value] of Object.entries(quote)) {
      if (value && key && value.toString().toLowerCase().includes(searchL))
        return true;
    }

    // Return true if the name of a book is in the search string
    if (this.filterForPublisherEdition(quote, searchL)) return true;
    if (this.filterForBibleBook(quote, searchL)) return true;
    if (this.filterForTestament(quote, searchL)) return true;

    return false;
  };

  // Return true, if the given quote should be displayed in respect to the currently selected dropdowns
  filterForDropdowns(quote) {
    const { bookPublisherDropdown, bibleBookDropdown, testamentDropdown, statusDropdown } =
      this.state;

    if (!quote.hasOwnProperty("book_publisher_edition")) return false;
    if (!quote.hasOwnProperty("bible_book")) return false;
    if (!quote.hasOwnProperty("bible_testament")) return false;

    if (
      bookPublisherDropdown !== -1 &&
      quote.book_publisher_edition !== bookPublisherDropdown
    )
      return false;

    if (bibleBookDropdown !== -1 && quote.bible_book !== bibleBookDropdown)
      return false;

    if (testamentDropdown !== -1 && quote.bible_testament !== testamentDropdown)
      return false;

    if(quote.hasOwnProperty("published")){
      if((statusDropdown === 0 && quote.published) || (statusDropdown === 1 && !quote.published))
        return false;
    }

    return true;
  }

  // Check if the searchString is contained in the quotes Publisher Edition
  filterForPublisherEdition(quote, searchString) {
    const value = quote.hasOwnProperty("book_publisher_edition")
      ? quote.book_publisher_edition
      : "";

    if (
      Number.isInteger(value) &&
      bookPublisherEditions
        .find((e) => e.id === value)
        .name.toLowerCase()
        .includes(searchString)
    )
      return true;
    return false;
  }

  // Check if the searchString is contained in the quotes Bible book
  filterForBibleBook(quote, searchString) {
    const value = quote.hasOwnProperty("bible_book") ? quote.bible_book : "";

    if (
      Number.isInteger(value) &&
      this.props.bible_books
        .find((e) => e.id === value)
        .name.toLowerCase()
        .includes(searchString)
    )
      return true;
    return false;
  }

  // Check if the searchString is contained in the quotes Bible testament
  filterForTestament(quote, searchString) {
    const value = quote.hasOwnProperty("bible_testament")
      ? quote.bible_testament
      : "";

    if (
      (Number.isInteger(value) &&
        value === 0 &&
        "AT".toLocaleLowerCase().includes(searchString)) ||
      (Number.isInteger(value) &&
        value === 1 &&
        "NT".toLocaleLowerCase().includes(searchString))
    )
      return true;
    return false;
  }

  // Get, filter, sort and paginate the quotes
  getPageData = () => {
    const {
      currentPage,
      sortColumn,
      searchQuery,
      pageSize,
      bookPublisherDropdown,
      bibleBookDropdown,
      testamentDropdown,
      statusDropdown,
    } = this.state;

    const { quotes: allQuotes } = this.props;

    let filteredQuotes = allQuotes;

    if (
      searchQuery ||
      bookPublisherDropdown !== -1 ||
      bibleBookDropdown !== -1 ||
      testamentDropdown !== -1 ||
      statusDropdown !== -1
    )
      filteredQuotes = allQuotes.filter(this.filterQuotes);

    const sortedQuotes = _.orderBy(
      filteredQuotes,
      [sortColumn.path],
      [sortColumn.order]
    );

    const quotes = paginate(sortedQuotes, currentPage, pageSize);
    const totalCount = allQuotes.length;

    this.props.onListChange(sortedQuotes);

    return { currentQuotes: quotes, totalCount, sortedQuotes };
  };

  render() {
    const { currentPage, searchQuery, pageSize, sortColumn } = this.state;
    const { currentQuotes: quotes, totalCount, sortedQuotes } = this.state;
    const { user, bible_books } = this.props;

    return (
      <div>
        <div className="d-flex flex-row pt-3">
          {user && (
            <React.Fragment>
              <div className="pl-2 pr-2">
                <Link
                  to="/quotes/new/edit"
                  className="btn btn-primary"
                  style={{ marginBottom: 20 }}
                >
                  Neues Zitat hinzufügen
                </Link>
              </div>
            </React.Fragment>
          )}
          {sortedQuotes.length !== 0 && ( // only show list if there is at least one quote currently shown
            <div className="pl-2 pr-2">
              <DownloadQuotesList
                quotes={sortedQuotes}
                bible_books={bible_books}
              />
            </div>
          )}
          <div className="pl-2 pr-2 flex-grow-1">
            <DropdownFilter
              value={searchQuery}
              options={bookPublisherEditions}
              placeholder={"Ausgabe"}
              onChange={this.handleBookPublisherDropdown}
            />
          </div>
          <div className="pl-2 pr-2 flex-grow-1">
            <DropdownFilter
              value={searchQuery}
              options={[
                { id: 0, name: "AT" },
                { id: 1, name: "NT" },
              ]}
              placeholder={"Testament"}
              onChange={this.handleTestamentDropdown}
            />
          </div>
          <div className="pl-2 pr-2 flex-grow-1">
            <DropdownFilter
              value={searchQuery}
              options={bible_books}
              placeholder={"Buch der Bibel"}
              onChange={this.handleBibleBookDropdown}
            />
          </div>
          {user && <div className="pl-2 pr-2 flex-grow-1">
            <DropdownFilter
              value={searchQuery}
              options={[
                { id: 0, name: "Entwurf" },
                { id: 1, name: "Veröffentlicht" },
              ]}
              placeholder={"Status"}
              onChange={this.handleStatusDropdown}
            />
          </div>}
        </div>
        <div className="d-flex flex-row">
          <div className="pl-2 pr-2 pb-3 flex-grow-1">
            <SearchField value={searchQuery} onChange={this.handleSearch} />
          </div>
        </div>

        <QuotesTable
          quotes={quotes}
          sortColumn={sortColumn}
          searchQuery={searchQuery}
          onSort={this.handleSort}
          getNextQuote={this.props.getNextQuote}
          getPreviousQuote={this.props.getPreviousQuote}
          bible_books={this.props.bible_books}
        />
        <Pagination
          itemsCount={sortedQuotes.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
        {sortedQuotes.length === totalCount && <p>Insgesamt {totalCount} Zitate in der Datenbank.</p>}
        {sortedQuotes.length !== totalCount && <p>Zeige {sortedQuotes.length} von {totalCount} Zitate in der Datenbank.</p>}
      </div>
    );
  }
}

export default Quotes;
