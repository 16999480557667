import React, { Component } from "react";
import EditBibleBook from "./editBibleBook";
import {
  deleteBibleBook,
  saveBibleBook,
} from "../../services/bibleBooksService";
import BibleBooksTable from "./bibleBooksTable";
import { toast } from "react-toastify";
import _ from "lodash";

class BibleBooks extends Component {
  state = {
    books: this.props.books,
    toast_id: null,
  };

  async componentDidMount() {
    this.props.onRefresh();

    if (this.props.books.length === 0) {
      const id = toast("Lade Bücher...");
      this.setState({ toast_id: id });
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.books !== this.props.books)
      this.setState({ books: this.props.books });

    if (this.props.books.length > 0 && this.state.toast_id != null)
      toast.dismiss(this.state.toast_id);
  }

  handleSort = () => {}; // Not needed

  /**
   * Send a new or edited book to the server
   * @param {*} book
   */
  handleBookUpdate = async (book) => {
    const originalBooks = this.state.books;
    saveBibleBook(book)
      .then((response) => {
        const data = response.data;
        if (data && data.id > -1) {
          toast.success("Gespeichert");
        }

        let newBooks = originalBooks.filter((b) => b.id !== data.id); // Remove updated book
        newBooks.push(data); // Add updated book
        this.setState({ books: newBooks });
      })
      .catch((error) => {
        toast.error("Error " + error.response.status);
      });
  };

  handleClickToEdit = (book) => {
    const books = [...this.state.books]; // Clone the books array
    const index = books.indexOf(book); // Get the index of the given book
    books[index] = { ...books[index] }; // Clone the book object
    books[index].edit = !books[index].edit; // Toggle edit state
    this.setState({ books });
  };

  handleBookDelete = async (book) => {
    const originalBooks = this.state.books;
    const books = originalBooks.filter((b) => b.id !== book.id);
    this.setState({ books });

    deleteBibleBook(book.id)
      .then((response) => {
        if (response.status === 200) toast.success("Buch gelöscht");
      })
      .catch((ex) => {
        this.setState({ books: originalBooks });
        return;
      });
  };

  render() {
    const { books } = this.state;

    const sortedBooks = _.orderBy(books, "name", "asc");

    return (
      <div className="row">
        <div className="col-12">
          <EditBibleBook
            book={{ name: "", testament: 0 }} // Passing an empty object: this component is for creating a new book
            name=""
            onBookUpdated={this.handleBookUpdate}
          />
        </div>
        <div className="col-12">
          <BibleBooksTable
            bibleBooks={sortedBooks}
            sortColumn=""
            searchQuery=""
            onSort={this.handleSort}
            onBookClick={this.handleClickToEdit}
            onBookUpdated={this.handleBookUpdate}
            onBookDelete={this.handleBookDelete}
          />
        </div>
      </div>
    );
  }
}

export default BibleBooks;
