import React from "react";

const SearchField = ({
  value,
  label = "Suchbegriff eingeben...",
  onChange,
}) => {
  return (
    <input
      name={label}
      id={label}
      placeholder={label}
      className="form-control"
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  );
};

export default SearchField;
