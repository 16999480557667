import Joi from "joi-browser";
import React from "react";
import { toast } from "react-toastify";
import userService from "../../services/userService";
import Form from "../common/form/form";

class AddUser extends Form {
  state = {
    data: { name: "", email: "" },
    errors: {},
    response: { email: "", password: "" },
  };

  schema = {
    name: Joi.string().required().min(3).max(100).label("Name"),
    email: Joi.string().required().email().max(100).label("Email"),
  };

  doSubmit = () => {
    const { name, email } = this.state.data;
    const password = randomString(16);

    userService
      .addUser(name, email, password)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            response: { email: email, password: password },
            data: { name: "", email: "" },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 422)
          toast.error(
            "Fehler: Möglicherweise existiert bereits ein Account mit dieser Adresse."
          );
      });
  };

  render() {
    const { email, password } = this.state.response;
    return (
      <React.Fragment>
        {password && (
          <div class="alert alert-success mt-3" role="alert">
            <p>
              Account erfolgreich erstellt. Die Zugangsdate lauten wie folgt.
            </p>
            <ul>
              <li>
                <li>
                  E-Mail-Adresse:<b> {email}</b>
                </li>
                <li>
                  Passwort:<b> {password}</b>
                </li>
              </li>
            </ul>
            Das Passwort kann über die Rücksetz-Funktion beim Login jederzeit
            geändert werden. :-)
          </div>
        )}
        <form
          onSubmit={this.handleSubmit}
          className="jumbotron form-signin p-3 mt-3"
        >
          <h1 className="h3 mb-3 font-weight-normal">Nutzer*in hinzufügen</h1>

          {this.renderInputField("name", "Name")}
          {this.renderInputField("email", "E-Mail-Adresse")}

          {this.renderSubmitButton("Speichern")}
        </form>
      </React.Fragment>
    );
  }
}

function randomString(length) {
  var result = [];
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

export default AddUser;
