import React from "react";
import { CSVLink } from "react-csv";

const DownloadQuotesList = ({ quotes, bible_books }) => {
  function mapToList(quotes) {
    const returnList = [];
    quotes.map((item) => {
      returnList.push({
        Datensatz: item.id,
        Zitat_Buch: item.quote_book,
        Zitat_Bibel: item.quote_bible,
        Buch_Titel: item.book_title,
        Textabweichungen: item.book_text_deviation,
        Anmerkungen: item.book_remarks,
        Bibelstelle_Vers: item.bible_book_verse,
        Ausgabe: item.book_publisher_edition,
        Auflage: item.book_edition_number,
        Jahr: item.book_edition_year,
        Seite: item.book_page,
        Testament: getListEntry("bible_testament", item),
        Buch_in_Bibel: getListEntry("bible_book", item),
        Verknuepfte_Zitate: getListEntry("linked_quotes", item),
      });
      return true;
    });
    return returnList;
  }

  function getListEntry(key, data) {
    switch (key) {
      case "linked_quotes":
        return data ? data.linked_quotes.map((entry) => entry) : "";
      case "bible_testament":
        return data.bible_testament === 0 ? "AT" : "NT";
      case "bible_book":
        const book = bible_books.find(
          (element) => element.id === data.bible_book
        );
        return book ? book.name : "";
      default:
        return data;
    }
  }

  return (
    <CSVLink
      data={mapToList(quotes)}
      filename={"bibeldb_download.csv"}
      separator={";"}
      className="btn btn-outline-dark"
    >
      Liste herunterladen
    </CSVLink>
  );
};

export default DownloadQuotesList;
