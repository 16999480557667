import React from "react";
import { Redirect } from "react-router";

import Joi from "joi-browser";

import Form from "./common/form/form";
import auth from "../services/authService";
import LoginImg from "../graphics/box-arrow-in-right.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  schema = {
    username: Joi.string().required().email().label("Nutzername"),
    password: Joi.string().required().min(6).label("Passwort"),
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const expired = query.get("expired");

    if (expired === "true") {
      toast.warn("Die Sitzung ist abgelaufen, bitte melde dich erneut an :-)");
    }
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.username, data.password);

      const { state } = this.props.location; // Get redirect location
      window.location = state ? state.from.pathname : "/"; // Full aplication reload
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errors = { ...this.state.errors };
        errors.username = "Email oder Passwort stimmt nicht";
        this.setState({ errors });
      }
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <React.Fragment>
        <div className="signin-container">
          <form onSubmit={this.handleSubmit} className="jumbotron form-signin">
            <img
              className="mb-4"
              src={LoginImg}
              alt=""
              width="72"
              height="72"
              id="login-img"
            />
            <h1 className="h3 mb-3 font-weight-normal">Bitte melde dich an</h1>

            {this.renderInputField("username", "Nutzername (E-Mail)")}
            {this.renderInputField("password", "Passwort", "password")}

            <p>
              <Link to="/forgot-password">Passwort vergessen</Link>
            </p>

            {this.renderSubmitButton("Login")}
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginForm;
