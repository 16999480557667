import React from "react";

import { Redirect } from "react-router";

import Joi from "joi-browser";

import Form from "./common/form/form";
import auth from "../services/authService";
import MailIcon from "../graphics/envelope.svg";
import { toast } from "react-toastify";

class ForgotPassword extends Form {
  state = {
    data: { username: "" },
    errors: {},
  };

  schema = {
    username: Joi.string().required().email().label("Nutzername"),
  };

  doSubmit = async () => {
    const { data } = this.state;
    auth
      .sendPasswordForgotten(data.username)
      .then((response) => {
        if (response.status === 200) {
          toast.info(
            "Falls es die E-Mail-Adresse " +
              data.username +
              " in der Datenbank gibt, haben wir dir ein Mail geschickt."
          );

          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        toast.error("Fehler beim Senden des E-Mails :-(");
      });
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <React.Fragment>
        <div className="signin-container">
          <form onSubmit={this.handleSubmit} className="jumbotron form-signin">
            <img
              className="mb-4"
              src={MailIcon}
              alt=""
              width="72"
              height="72"
              id="login-img"
            />
            <h1 className="h3 mb-3 font-weight-normal">
              Rücksetz-Link anfordern
            </h1>

            {this.renderInputField("username", "Nutzername (E-Mail)")}
            {this.renderSubmitButton("Senden")}
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
