import React from "react";

const DropdownFilter = ({ value, options, placeholder, onChange }) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <select
          className="form-control"
          onChange={(e) => onChange(Number.parseInt(e.currentTarget.value))}
        >
          <option value="-1">{placeholder}</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </React.Fragment>
  );
};

export default DropdownFilter;
