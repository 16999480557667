import axios from "axios";
import { toast } from "react-toastify";
import { invalidateUser } from "./authService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

// Interceptor gets called when an http error happens
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500; // Excpected error -> error code between 400 and 500

  if (!expectedError) {
    if (error.response)
      toast.error(
        "Ein unerwartetet Fehler ist aufgetreten. Statuscode: " +
          error.response.status
      );
    else
      toast.error(
        "Ein unerwartetet Fehler ist aufgetreten. Statuscode unbekannt."
      );
  }

  if (error.response && error.response.status === 403)
    toast.error("Fehler: Mangelnde Berechtigungen");

  if (error.response && error.response.status === 401) {
    invalidateUser();
    window.location = "/"; // Full aplication reload
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  // Include the auth token in http requests
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};

export default http;
