import http from "./httpService";

const apiEndpoint = "/quotes";

function quoteUrl(id) {
  return apiEndpoint + "/" + id;
}

export function getQuotes() {
  return http.get(apiEndpoint);
}

export function getQuote(id) {
  return http.get(quoteUrl(id));
}

export function saveQuote(quote) {
  if (quote.id !== -1) {
    const body = { ...quote };
    delete body.id;
    return http.put(quoteUrl(quote.id), body);
  }

  return http.post(apiEndpoint, quote);
}

export function deleteQuote(id) {
  return http.delete(quoteUrl(id));
}

export function linkQuotes(id, id2) {
  const body = {
    id: id,
    link_id: id2,
  };
  return http.post(apiEndpoint + "/link", body);
}

export function unlinkQuotes(id, id2) {
  const body = {
    id: id,
    link_id: id2,
  };
  return http.post(apiEndpoint + "/unlink", body);
}

export const keyNames = {
  id: "Datensatz-ID",
  created_at: "Erstelldatum",
  updated_at: "Letzte Bearbeitung",
  quote_book: "Bibel-Zitat",
  quote_bible: "Originalstelle (Bibel)",
  book_title: "Buch-Titel",
  book_text_deviation: "Textstelle Karl May-Buch",
  book_remarks: "Anmerkungen",
  bible_book_verse: "Bibelstelle (Vers)",
  creator: "Ersteller",
  book_publisher_edition: "Ausgabe",
  book_edition_number: "Auflage",
  book_edition_year: "Jahr",
  book_page: "Seite",
  bible_testament: "Testament",
  bible_book: "Buch der Bibel",
  published: "Veröffentlicht",
  year_is_ca: "Jahresangabe nicht exakt"
};

export const bookPublisherEditions = [
  { id: 0, name: "Fehsenfeld" },
  { id: 1, name: "Bamberger" },
  { id: 2, name: "Historisch-kritische Ausgabe" },
  { id: 3, name: "Ueberreuter-Ausgabe" },
  { id: 4, name: "Pawlak" },
  { id: 5, name: "Weltbild" },
];

const quotesService = {
  getQuotes,
  getQuote,
  keyNames,
  bookPublisherEditions,
};

export default quotesService;
