import http from "./httpService";

const apiEndpoint = "/auth";
const userKey = "user";

export async function login(email, password) {
  await http.get("/csrf-cookie");

  const { data } = await http.post(apiEndpoint + "/login", {
    email,
    password,
  });
  localStorage.setItem("user", btoa(JSON.stringify(data.data)));
}

export async function logout() {
  try {
    await http.post(apiEndpoint + "/logout");
  } catch (ecxeption) {
    console.log("logout Exception " + ecxeption);
  }

  invalidateUser();
}

export function invalidateUser() {
  document.cookie =
    "laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  localStorage.removeItem(userKey);
}

/**
 * Check if the user is logged in and invalidate and redirect if this is not the case
 */
export async function checkLogin() {
  if (getCurrentUser() == null) return;
  try {
    await http.get("/me");
  } catch (error) {
    if (error.response && error.response.status === 401) {
      invalidateUser();
      window.location = "/login?expired=true"; // Full aplication reload
    }
  }
}

export function getCurrentUser() {
  try {
    const userEncoded = localStorage.getItem(userKey);

    if (!userEncoded) return null;

    const userJson = atob(userEncoded);
    const user = JSON.parse(userJson);

    return user;
  } catch (error) {
    return null;
  }
}

export async function sendPasswordForgotten(email) {
  await http.get("/csrf-cookie");

  return http.post(apiEndpoint + "/forgot-password", {
    email,
  });
}

export async function resetPassword(
  email,
  password,
  password_confirmation,
  token
) {
  await http.get("/csrf-cookie");

  return http.post(apiEndpoint + "/reset-password", {
    email,
    password,
    password_confirmation,
    token,
  });
}

const auth = {
  login,
  logout,
  getCurrentUser,
  sendPasswordForgotten,
  resetPassword,
  invalidateUser,
  checkLogin,
};

export default auth;
