import React, { Component } from "react";
import { toast } from "react-toastify";
import { linkQuotes } from "../../services/quotesService";
import Link from "../../graphics/link-45deg.svg";

class LinkQuotes extends Component {
  state = {
    id: "", // The id to link with. The second id is passed via props
  };

  onInputChanged = (input) => {
    this.setState({ id: input });
  };

  saveChanges = async () => {
    this.setState({ id: "" });

    const linkId = this.state.id;

    const status = await linkQuotes(this.props.id, this.state.id);
    if (status.data === "success") {
      toast.success("Zitate verknüpft!");
      this.props.onRefresh();
    } else if (status.data === "already linked")
      toast.warn("Diese Zitate sind bereits verknüpft!");
    else if (status.data === "error") toast.error("Fehler beim Verknüpfen!");
    else if (status.data === "not found")
      toast.error("Zitat mit ID " + linkId + " konnte nicht gefunden werden");
  };

  render() {
    return (
      <React.Fragment>
        <div className="p-2 ml-auto">
          <label htmlFor="inputNewBook" className="col-form-label">
            Zitat verknüpfen:
          </label>
        </div>
        <div className="p-2">
          <input
            type="number"
            placeholder="ID des anderen Zitats"
            id="inputNewBook"
            className="form-control"
            style={{ minWidth: 100 }}
            value={this.state.id}
            onChange={(e) => this.onInputChanged(e.currentTarget.value)}
          />
        </div>
        <div className="p-2" style={{ textAlign: "center" }}>
          <button className="btn btn-success" onClick={this.saveChanges}>
            <img src={Link} alt="" className="mr-2 mb-1" />
            Verknüpfen
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default LinkQuotes;
