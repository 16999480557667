import React, { Component } from "react";
import Table from "../common/table/table";
import userService from "../../services/userService";
import { toast } from "react-toastify";
import AddUser from "./addUser";

class UserTable extends Component {
  state = {
    user: [],
  };

  componentDidMount() {
    userService.getUser().then((result) => {
      this.setState({ user: result.data });
    });
  }

  columns = [
    {
      path: "name",
      label: "Name",
    },
    {
      path: "email",
      label: "Email-Adresse",
    },
    {
      path: "delete",
      label: "Account Löschen",
      content: (user) =>
        !user.admin && (
          <div
            className="btn btn-outline-danger"
            onClick={() => {
              if (
                window.confirm(
                  "Willst du den Account " +
                    user.name +
                    " (" +
                    user.email +
                    ") wirklich löschen?"
                )
              )
                this.onUserDelete(user);
            }}
          >
            Löschen
          </div>
        ),
    },
  ];

  onUserDelete = (user) => {
    const originalUser = this.state.user;
    const users = originalUser.filter((b) => b.id !== user.id);
    this.setState({ user: users });

    userService
      .deleteUser(user.id)
      .then((resp) => {
        if (resp.status === 200) toast.success("Nutzer*in gelöscht");
      })
      .catch((error) => {
        // If it's a 404 error, the user was not found. Meaning it is already deleted
        if (error.response.status === 404) toast.success("Nutzer*in gelöscht");
        else {
          // Else, deleting went wron. Display the original data
          this.setState({ user: originalUser });
          console.log(error);
          toast.error("Fehler");
        }
      });
  };

  onSort = () => {};

  render() {
    return (
      <div className="row" id="user-container">
        <div className="col-12 m-3">
          <AddUser />
        </div>
        <div className="col">
          <Table
            columns={this.columns}
            data={this.state.user}
            sortColumn={""}
            onSort={this.onSort}
          />
        </div>
      </div>
    );
  }
}

export default UserTable;
