import React from "react";

const Footer = ({ user }) => {
  if (user) return "";
  return (
    <footer>
      <a href="http://www.kmcoe.at/">
        <p id="copyright">© Karl May Club Österreich {new Date().getFullYear()}</p>
      </a>
    </footer>
  );
};

export default Footer;
