import React from "react";

const CheckBox = ({ name, error, label, value, disabled, ...rest }) => {
  // ...rest -> rest operator to get all other properties from the props object
  return (
    <div className="form-check">
      <input
        {...rest}
        name={name}
        id={name}
        className="form-check-input"
        type="checkbox"
        checked={value}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
