import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getCurrentUser } from "../../services/authService";
import ArrowLeft from "../../graphics/caret-left-fill.svg";
import ArrowRight from "../../graphics/caret-right-fill.svg";
import Pencil from "../../graphics/pencil-fill.svg";
import Trash from "../../graphics/trash-fill.svg";

import {
  deleteQuote,
  keyNames,
  unlinkQuotes,
} from "../../services/quotesService";
import LinkQuotes from "./linkQuotes";
import {bookPublisherEditions} from "../../services/quotesService";

class ShowQuote extends Component {
  state = {
    quote: {},
    error_sent: false,
    toast_id: null,
    previousPage: -1,
    nextPage: -1,
  };

  componentDidMount() {
    this.props.onRefresh();
    this.prepareNavButtons();
    this.populateTable();
  }

  componentDidUpdate(previousProps, previousState) {
    const id = this.getCurrentId();

    // If quotes list got update or user navigated to another quote-page
    if (
      previousProps.quotes !== this.props.quotes ||
      (previousState.quote.id && previousState.quote.id !== id)
    ) {
      this.populateTable();
      this.prepareNavButtons();
    }
  }

  prepareNavButtons() {
    const query = new URLSearchParams(this.props.location.search);
    const previousPageString = query.get("p");
    const nextPageString = query.get("n");

    const previousPage = previousPageString ? parseInt(previousPageString) : -1;
    const nextPage = nextPageString ? parseInt(nextPageString) : -1;

    this.setState({ previousPage, nextPage });
  }

  /**
   * Filter Quotes list for the wanted quote
   */
  populateTable() {
    const id = this.getCurrentId();

    const quote = this.props.quotes.find((element) => element.id === id);
    if (quote) {
      toast.dismiss(this.state.toast_id); // Close the loading-toast
      this.setState({ quote }); // Set the data
    }

    // If the quote could not be found
    if (this.props.quotes.length > 0 && !quote && !this.state.error_sent) {
      toast.error("Zitat konnte nicht geladen werden!");
      this.setState({ error_sent: true });
      this.props.history.push("/");
    } else if (this.props.quotes.length === 0)
      // If we received an empty list, maybe the app component did not load the quotes from the server yet
      this.setState({ toast_id: toast("Lade Zitat...") });
  }

  getCurrentId() {
    const idString = this.props.match.params.id;
    return parseInt(idString);
  }

  getKeyName = (key) => {
    if (keyNames[key] != null) return keyNames[key];
    if (key === "linked_quotes") return "";
    return key;
  };

  handleDelete = async () => {
    deleteQuote(this.state.quote.id)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Zitat gelöscht");
          this.props.history.push("/");
        }
      })
      .catch((ex) => {
        if (ex.response && ex.response.status === 404) toast.error("Error");
      });
  };

  handleUnlink = async (id) => {
    const status = await unlinkQuotes(this.state.quote.id, id);
    if (status.data === "success") toast.success("Verknüpfung aufgehoben.");

    this.props.onRefresh();
  };

  /**
   * Render a data cell
   * @param {*} key
   * @param {*} data
   * @returns
   */
  renderDataCell(key, data) {
    switch (key) {
      case "id":
        return data === this.state.quote.id ? (
          data
        ) : (
          <React.Fragment>
            <p>{data}</p>
            <Link to={"/quotes/" + data} className="btn btn-info">
              Gehe zu Zitat {data}
            </Link>
          </React.Fragment>
        );
      case "linked_quotes":
        return "";
      case "bible_testament":
        return data === 0 ? "AT" : "NT";
      case "bible_book":
        const book = this.props.bible_books.find(
          (element) => element.id === data
        );
        return book ? book.name : "";
      case "created_at":
      case "updated_at":
        let date = new Date(data * 1000);
        return (
          date.getDate() +
          "." +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear() +
          " " +
          date.getHours() +
          ":" +
          (date.getUTCMinutes() < 10
            ? "0" + date.getUTCMinutes()
            : date.getUTCMinutes())
        );
      case "published":
        return data ? "Veröffentlicht" : "Entwurf";
      case "book_publisher_edition":
        const edition = bookPublisherEditions.find((element) => element.id === data);
        return edition ? edition.name : "";
      case "year_is_ca":
        return data ? "Jahresangabe ist ungefähr (keine Exakte Angabe vorhanden)" : "Jahresangabe ist exakt";
      default:
        return data;
    }
  }

  /**
   * Render a button to unlink the quote for each linked quote
   * @returns
   */
  renderUnlinkButton() {
    if (!this.state.quote.linked_quotes) return;

    if (this.state.quote.linked_quotes.length > 0 && getCurrentUser()) {
      return (
        <tr key="delete">
          <th scope="row">Verknüpfung löschen</th>
          <td key="thisQuote"></td>
          {this.state.quote.linked_quotes.map((id) => (
            <td key={id}>
              {
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Verknüpfung wirklich löschen? (Die einzelnen Zitate werden dadurch nicht gelöscht)"
                      )
                    )
                      this.handleUnlink(id);
                  }}
                  className="btn btn-warning ml-auto"
                >
                  Verknüpfung löschen
                </button>
              }
            </td>
          ))}
        </tr>
      );
    }
  }

  render() {
    const { quote, previousPage, nextPage } = this.state;
    const { getPreviousQuote: prev, getNextQuote: next } = this.props;
    const user = getCurrentUser();
    const editLink = `/quotes/${quote.id}/edit`;
    const previousPageLink = `/quotes/${previousPage}?p=${prev(
      previousPage
    )}&n=${next(previousPage)}`;
    const nextPageLink = `/quotes/${nextPage}?p=${prev(nextPage)}&n=${next(
      nextPage
    )}`;

    return (
      <React.Fragment>
        <div className="d-flex flex-row mt-3 mb-3">
          <div className="pt-2 pb-2">
            {previousPage !== -1 ? (
              <Link to={previousPageLink} title="Vorheriges Zitat">
                <img
                  src={ArrowLeft}
                  alt="Vorheriges Zitat"
                  width="40"
                  height="40"
                />
              </Link>
            ) : (
              <img
                src={ArrowLeft}
                alt="Vorheriges Zitat"
                width="40"
                height="40"
                className="filter-grey"
              />
            )}
          </div>

          <div className="pt-2 pb-2">
            {nextPage !== -1 ? (
              <Link to={nextPageLink} title="Nächstes Zitat">
                <img
                  src={ArrowRight}
                  alt="Nächstes Zitat"
                  width="40"
                  height="40"
                />
              </Link>
            ) : (
              <img
                src={ArrowRight}
                alt="Nächstes Zitat"
                width="40"
                height="40"
                className="filter-grey"
              />
            )}
          </div>

          {user && (
            <React.Fragment>
              <div className="p-2">
                <Link to={editLink} className="btn btn-primary">
                  <img src={Pencil} alt="" className="mr-2 mb-1" />
                  Bearbeiten
                </Link>
              </div>
              <div className="p-2">
                <button
                  onClick={() => {
                    if (window.confirm("Zitat wirklich löschen?"))
                      this.handleDelete();
                  }}
                  className="btn btn-danger"
                >
                  <img src={Trash} alt="" className="mr-2 mb-1" />
                  Löschen
                </button>
              </div>
            </React.Fragment>
          )}
          {user && (
            <LinkQuotes
              id={this.getCurrentId()}
              onRefresh={this.props.onRefresh}
            />
          )}
        </div>

        <div className="jumbotron">
          <table className="table">
            <tbody>
              {this.renderUnlinkButton()}
              {Object.entries(quote).map(([key, data]) => (
                <tr key={key}>
                  <th scope="row">{this.getKeyName(key)}</th>

                  {/* Render this cell */}
                  <td key={key}>{this.renderDataCell(key, data)}</td>

                  {/* Render the cells of linked quotes if we have some */}
                  {this.state.quote.linked_quotes &&
                    this.state.quote.linked_quotes.map((id) => {
                      // Get the linked quote
                      const linked = this.props.quotes.find((i) => i.id === id);

                      return (
                        <td key={key + linked.id}>
                          {this.renderDataCell(key, linked[key])}
                        </td>
                      );
                    })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default ShowQuote;
