import React, { Component } from "react";
import bibleBookService from "../../services/bibleBooksService";
import Check from "../../graphics/save2-fill.svg";

class EditBibleBook extends Component {
  state = {
    book: this.props.book,
  };

  saveChanges = () => {
    const { book } = this.state;
    this.props.onBookUpdated(book);

    book.name = "";
    this.setState({ book });
  };

  /**
   * When making changes to an existing book, one can click the abort button (toggle the state of book.edit)
   */
  abbort = () => {
    this.props.onBookClick(this.props.book);
  };

  onInputChanged = ({ currentTarget: input }) => {
    const book = { ...this.state.book };
    if (input.name === "title") book.name = input.value;
    if (input.name === "testament") book.testament = parseInt(input.value);

    this.setState({ book });
  };

  render() {
    const isEdit = this.state.book.id ? true : false;
    const parentClass = isEdit
      ? "row g-3 align-items-center "
      : "jumbotron row g-3 align-items-center p-4 mt-3 mb-3";

    return (
      <div className={parentClass}>
        {!this.state.book.id && (
          <div className="col-auto">
            <label htmlFor="inputNewBook" className="col-form-label">
              Neues Buch hinzufügen:
            </label>
          </div>
        )}
        {/* Buch */}
        <div className="col">
          <input
            type="text"
            id="inputNewBook"
            className="form-control mb-1 mt-1"
            name="title"
            style={{ width: "100%", minWidth: 200 }}
            onChange={(e) => this.onInputChanged(e)}
            value={this.state.book.name}
          />
        </div>
        {/* Testament */}
        <div className="col-auto">
          <select
            className="form-control"
            onChange={(e) => this.onInputChanged(e)}
            name="testament"
            value={this.state.book.testament}
          >
            {bibleBookService.testaments.map((testament) => {
              return (
                <option value={testament.id} key={testament.id}>
                  {testament.name}
                </option>
              );
            })}
          </select>
        </div>
        {/* Speichern / Abbrechen */}
        <div className="col-auto">
          <button
            className="btn btn-success"
            onClick={this.saveChanges}
            disabled={
              (this.state.book.name === this.props.book.name &&
                this.state.book.testament === this.props.book.testament) ||
              (!isEdit && this.state.book.name === this.props.book.name)
            }
          >
            <img src={Check} alt="" className="mr-2 mb-1" />
            Speichern
          </button>
          {this.state.book.id && (
            <button
              className="btn btn-warning"
              onClick={this.abbort}
              style={{ marginLeft: 10 }}
            >
              Abbrechen
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default EditBibleBook;
