import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import CheckBox from "./checkBox";
import TextArea from "./textarea";
import Check from "../../../graphics/save2-fill.svg";

class Form extends Component {
  state = { data: {}, errors: {} };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message; // Map aray to object
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value }; // The key will be set at runtime to the value in name, eg username or password
    const schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? this.getErrorMessage(error.details[0]) : null;
  };

  getErrorMessage = (errorDetails) => {
    switch (errorDetails.type) {
      case "any.empty":
      case "number.min": // Verwendet für die leere Option bei Input-select
        return errorDetails.context.label + " darf nicht leer sein.";
      case "number.base":
        return errorDetails.context.label + " muss eine ganze Zahl sein.";
      case "string.min":
        return (
          errorDetails.context.label +
          " muss mindestens " +
          errorDetails.context.limit +
          " Zeichen lang sein."
        );
      case "string.max":
        return (
          errorDetails.context.label +
          " darf maximal " +
          errorDetails.context.limit +
          " Zeichen lang sein."
        );
      case "string.email":
        return (
          errorDetails.context.label + " muss eine gültige E-Mail-Adresse sein."
        );
      default:
        return errorDetails.type; // errorDetails.message;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault(); // Prevent submission to server

    const errors = this.validate();
    this.setState({ errors: errors || {} }); // Set to errors object. If errors object is null, set to empty object
    if (errors) return;

    this.doSubmit();
  };

  // Destructuring the e property, getting the e.currentTarget and renaming it to input
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.type === "checkbox" ? input.checked : input.value; // If its a checkbox, the value is in input.checked

    this.setState({ data, errors });
  };

  renderSubmitButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        <img src={Check} alt="" className="mr-2 mb-1" />
        {label}
      </button>
    );
  }

  renderInputField(name, label, type = "text", min = "", max = "") {
    const { data, errors, disabled } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        min={min}
        max={max}
        disabled={disabled}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }

  renderInputTextArea(name, label, rows = 3) {
    const { data, errors, disabled } = this.state;

    return (
      <TextArea
        name={name}
        value={data[name]}
        label={label}
        rows={rows}
        disabled={disabled}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }

  renderInputSelect(name, label, options) {
    const { data, errors, disabled } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        disabled={disabled}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }

  renderInputCheckbox(name, label) {
    const { data, errors, disabled } = this.state;

    return (
      <CheckBox
        name={name}
        value={data[name]}
        label={label}
        disabled={disabled}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }
}

export default Form;
