import React from "react";
import PropTypes from "prop-types";
import _ from "lodash"; // Utility library

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize); // Math.ceil = aufrunden
  if (pagesCount === 1) return null; // If there is only one page we don't need pagination

  const pages = _.range(1, pagesCount + 1); // Generate array from 1 to pagesCount+1

  return (
    <ul className="pagination">
      {pages.map((page) => (
        <li
          className={
            page === currentPage
              ? "clickable page-item active"
              : "clickable page-item"
          }
          key={page}
        >
          <div className="page-link" onClick={() => onPageChange(page)}>
            {page}
          </div>
        </li>
      ))}
    </ul>
  );
};

// Type checking, see https://reactjs.org/docs/typechecking-with-proptypes.html for more info
Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
