import React from "react";

const TextArea = ({ name, error, label, rows, value, disabled, ...rest }) => {
  // ...rest -> rest operator to get all other properties from the props object
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <textarea
        value={value ? value : ""}
        {...rest}
        name={name}
        id={name}
        rows={rows}
        disabled={disabled}
        className="form-control"
      />
      {error && <div className="alert alert-danger mt-1">{error}</div>}
    </div>
  );
};

export default TextArea;
