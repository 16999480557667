import React, { Component } from "react";
import Table from "../common/table/table";
import EditBibleBook from "./editBibleBook";
import Pencil from "../../graphics/pencil-primary.svg";
import Trash from "../../graphics/trash-red.svg";

class BibleBooksTable extends Component {
  columns = [
    {
      path: "name",
      label: "Buch",
      content: (book) =>
        book.edit ? (
          <EditBibleBook
            book={book}
            name={book.name}
            onBookUpdated={this.props.onBookUpdated}
            onBookClick={this.props.onBookClick}
          />
        ) : (
          book.name
        ),
    },
    {
      path: "testament",
      label: "Testament",
      content: (book) => (book.edit ? "" : book.testament === 1 ? "NT" : "AT"),
    },
    {
      path: "edit",
      label: "Bearbeiten",
      content: (book) =>
        !book.edit ? (
          <div
            className="btn btn-outline-primary"
            onClick={() => this.props.onBookClick(book)}
          >
            <img src={Pencil} alt="" className="mr-2 mb-1" />
            Bearbeiten
          </div>
        ) : (
          ""
        ),
    },
    {
      path: "delete",
      label: "Löschen",
      content: (book) => (
        <div
          className="btn btn-outline-danger"
          onClick={() => {
            if (window.confirm('Buch "' + book.name + '" wirklich löschen?'))
              this.props.onBookDelete(book);
          }}
        >
          <img src={Trash} alt="" className="mr-2 mb-1" />
          Löschen
        </div>
      ),
    },
  ];

  render() {
    const { bibleBooks, sortColumn, onSort } = this.props;

    return (
      <Table
        columns={this.columns}
        data={bibleBooks}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default BibleBooksTable;
